import React from "react";
import Image from "next/legacy/image";
import Link from "next/link";
import imageLoader from "../../utils/imageLoader";
import { CallToAction } from "../..";
import classNames from "classnames";

import "./ImagePullout.scss";

interface ImagePulloutProps {
  bgSrc: string;
  heading: string;
  text: string;
  callToAction: CallToAction;
}

export const ImagePullout = ({
  bgSrc,
  heading,
  text,
  callToAction,
}: ImagePulloutProps) => {
  return (
    <div className={classNames("image-pullout")}>
      <Image
        src={bgSrc}
        alt={heading}
        layout="fill"
        objectFit="cover"
        loader={imageLoader}
      />
      <div className={classNames("image-pullout__content")}>
        <h2
          className={classNames(
            "title has-text-white  mb-2",
            "is-size-1 is-size-2-mobile"
          )}
        >
          {heading}
        </h2>
        <div className="is-size-4 has-text-white mb-6">{text}</div>
        {callToAction?.url && (
          <Link
            className="button"
            target={callToAction.target}
            href={callToAction.url}
          >
            {callToAction.title || callToAction.url}
          </Link>
        )}
      </div>
    </div>
  );
};
