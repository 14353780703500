/* eslint-disable */

import React, { useId } from "react";
import classNames from "classnames";
import "./Checkbox.scss";
import { Icon, Icomoon } from "../../Icon/Icon";
import { FieldValues, FormState, UseFormRegisterReturn } from "react-hook-form";

interface Props {
  className?: string;
  label: string;
  subtext?: string;
  inputProps: WithRequired<JSX.IntrinsicElements["input"], "name">;
  register?: UseFormRegisterReturn;
  formState?: FormState<FieldValues>;
  dataTrackingId?: string;
}

export const Checkbox: React.FC<Props> = ({
  className,
  label,
  subtext,
  inputProps,
  register,
  formState,
  dataTrackingId,
}) => {
  const error = formState?.errors[inputProps.name];

  const innerId = inputProps?.id ?? useId();

  return (
    <>
      <label htmlFor={innerId} className={classNames(className, "form-checkbox")}>
        <div className="checkbox-container">
          <input
            {...inputProps}
            data-tracking-id={dataTrackingId}
            type="checkbox"
            id={innerId}
            {...register}
          />
          <Icon icomoon={Icomoon.checkmark} className="checkmark" />
          <div className="checkbox-text-container">
            <p className="checkbox-label">{label}</p>
            {subtext && <p className="checkbox-subtext">{subtext}</p>}
          </div>
        </div>
      </label>
      {!!error && <div className="help is-danger">{error.message?.toString()}</div>}
    </>
  );
};
