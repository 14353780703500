import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { validateEmailRegExp } from "../../../utils/validate-email";
import { InputWrapper } from "../wrapper";

type EmailInputProps = {
  label: string;
  helpMessage: string;
  dataTrackingId?: string;
} & WithRequired<JSX.IntrinsicElements["input"], "name">;

export const EmailInput = ({
  label,
  helpMessage,
  name = "email",
  dataTrackingId,
  ...props
}: EmailInputProps) => {
  const { setError, clearErrors } = useFormContext();
  const { t } = useTranslation();

  return (
    <InputWrapper
      label={{
        text: label || t("common:email", "Email"),
      }}
      input={{
        input: "input",
        props: {
          name,
          type: "email",
          placeholder: label || t("common:email", "Email"),
          ...props,
        },
      }}
      helpMessage={helpMessage}
      rules={{
        onBlur: (e) => {
          const email = e.target.value;

          if (email.trim() !== "" && !validateEmailRegExp.test(email)) {
            setError(name, {
              message: t("errors:EMAIL_NOT_VALID", "This email is not valid"),
              type: "pattern",
            });
          } else {
            clearErrors(name);
          }
        },
        required: t("errors:requiredField", "This is a required field"),
        pattern: validateEmailRegExp,
      }}
      icons={{
        valid: "checkmark",
      }}
      dataTrackingId={dataTrackingId}
    />
  );
};
