/* eslint-disable */

import React, { useState } from "react";
import CountrySelect, { CountrySelectProps } from "../../atoms/CountrySelect/CountrySelect";
import { Dialog } from "../Dialog";
import { useTranslation } from "react-i18next";

import "./LocaleModal.scss";

export type LocaleOption = Pick<CountrySelectProps, "label" | "locale" | "src">;

export type LocaleModalProps = {
  onClick?: CountrySelectProps["onClick"];

  onClose?: () => void;

  /** The list of locales that can be chosen from */
  locales?: LocaleOption[];
};

export const LocaleModal = ({ onClick, onClose, locales = defaultLocales }: LocaleModalProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation("ui");

  return (
    <Dialog
      isOpen={isOpen}
      ariaLabel={"Locale Modal"}
      role="alertdialog"
      autoFocus
      hasCloseButton
      onClose={onClose}>
      <div className="locale-modal">
        <h3 className="locale-modal__title">
          {t("LocaleModal_title", "Please choose your region")}
        </h3>
        <div className="locale-modal__list">
          {locales.map((props) => (
            <CountrySelect
              key={props.locale + props.label}
              onClick={(selectedLocale) => {
                setIsOpen(false);
                onClick?.(selectedLocale);
              }}
              {...props}
            />
          ))}
        </div>
      </div>
    </Dialog>
  );
};

export const defaultLocales: LocaleOption[] = [
  {
    label: "United States (EN)",
    locale: "en",
    src: "https://resources.travellocal.com/images/flags/locale/US.svg",
  },
  {
    label: "United Kingdom (EN)",
    locale: "en-gb",
    src: "https://resources.travellocal.com/images/flags/locale/GB.svg",
  },
  {
    label: "Deutschland (DE)",
    locale: "de",
    src: "https://resources.travellocal.com/images/flags/locale/DE.svg",
  },
  {
    label: "Rest of world (EN)",
    locale: "en",
    src: "https://resources.travellocal.com/images/flags/locale/WW.svg",
  },
];
